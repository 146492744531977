<!--
 * @Author: duanxin
 * @Date: 2023-07-26 18:16:36
 * @LastEditors: duanxin
 * @LastEditTime: 2023-08-03 13:45:00
 * @Description: 
-->
<template>
  <div class="radio-box-container">
    <el-radio
      v-for="({ label, value: val }, key) in radioList"
      :key="key"
      :value="value"
      :disabled="disabled"
      :label="label"
      @input="$emit('input', label)"
      >{{ val }}</el-radio
    >
  </div>
</template>

<script>
export default {
  name: 'a-radio',
  components: {},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      default: ''
    },
    disabled: {
      default: false
    },
    radioList: {
      default: () => []
    }
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="stylus" scoped>
.container
  .main
    margin: 0;

</style>
